<template>
  <div class="container relative mx-auto py-16">
    <transition
      enter-active-class="miniFadeInTop"
      leave-active-class="miniFadeOutBottom"
      mode="out-in"
    >
      <router-view
        name="page"
        :plan="plan"
        v-if="sub"
        :subscription="sub"
        class="relative z-10"
      />
    </transition>
  </div>
</template>

<script>
import { cloneDeep } from "lodash/fp";
export default {
  props: ["plan", "subscription"],
  computed: {
    background: function () {
      return this.$route.meta.background;
    },
    styleObject: function () {
      return "transform: rotate(" + (this.background.rotate || 0) + "deg)";
    },
  },
  data() {
    return {
      sub: null,
    };
  },
  mounted() {
    this.$store.dispatch("getSubscription").then((response) => {
      let sub = cloneDeep(response);

      sub.items.forEach((e) => {
        if (!e.isKitten) {
          e.isKitten = false;
        }
        if (!e.foodAmount) {
          e.foodAmount = 60;
        }
      });

      this.sub = Object.assign({}, this.sub, sub);
    });
  },
};
</script>

<style lang="scss" scoped>
.blob-container {
  width: 380px;
}
.outline-fill {
  @apply fill-current;
}
.rotate-12 {
}
</style>
